/* eslint-disable */
export default async (locale) => {
  const lang = locale.split('-')[0];
  await import('flatpickr/dist/flatpickr.css');
  try {
    if (lang !== 'en') await import(/* webpackChunkName: "flatpickr-[request]" */ 'flatpickr/dist/l10n/' + lang + '.js');
  } catch (e) {
    console.warn(e);
  }
};
